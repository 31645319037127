module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-11YERTKCZX","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Progate Journey","short_name":"Journey","description":"Web Product Development Learning Roadmap","lang":"en","start_url":"/en/","background_color":"#f9fbfe","theme_color":"#f9fbfe","display":"standalone","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"localize":[{"lang":"ja","start_url":"/ja/","name":"Progate Journey","short_name":"Journey","description":"Webプロダクト開発学習ロードマップ"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"818dfdccdc5eada6fdebbbb091e72959"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ja"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"siteUrl":"https://journey.prog-8.com","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
